<template>
	<div class="container-fluid animatedParent animateOnce">
		<!-- main content -->

		<div class="row">
			<div class="col-md-12">

				<div class="card mt-3 shadow">
					<div class="card-header">
						<div class="float-left mt-2">
							<h5>发放制度</h5>
						</div>
						
						<div class="float-right">
							<button @click="back" type="type" class="btn btn-outline-dark">
								<i class="icon icon-chevron-circle-left"></i>返回
							</button>
						</div>
					</div>
					<div class="card-body">
						<div class="needs-validation" novalidate="">
							
							<div class="form-group row">
							    <label for="inputPassword" class="col-sm-2 col-form-label offset-sm-2">福利分类</label>
							    <div class="col-sm-4">
							      <select disabled  class="form-control-plaintext" v-model="institution.category">
							      	<option v-bind:key="index" :value="item.value"
							      		v-for="(item,index) in categorylist">{{item.name}}</option>
							      </select>
							    </div>
							</div>
							
							<div class="form-group row">
							    <label for="inputPassword" class="col-sm-2 col-form-label offset-sm-2">制度类型</label>
							    <div class="col-sm-4">
							      <select disabled  class="form-control-plaintext" v-model="institution.expense_type_sub_category">
							      	<option v-bind:key="index" :value="item.value"
							      		v-for="(item,index) in subexpensetypelist">{{item.name}}</option>
							      </select>
							    </div>
							</div>
							
							<div class="form-group row">
							    <label for="inputPassword" class="col-sm-2 col-form-label offset-sm-2">制度名称</label>
							    <div class="col-sm-6">
							      <input disabled v-model="institution.institution_name" type="text" class="form-control-plaintext"
							      	placeholder="制度名称">
							    </div>
							</div>
							
							
							<div class="form-group row">
							    <label for="inputPassword" class="col-sm-2 col-form-label offset-sm-2">金额(元)</label>
							    <div class="col-sm-6">
							      <input disabled v-model="institution.quota_total" type="text" class="form-control-plaintext"
							      	placeholder="请输入额度金额">
							    </div>
							</div>
							
							<div class="form-group row">
							    <label for="inputPassword" class="col-sm-2 col-form-label offset-sm-2">制度名称</label>
							    <div class="col-sm-6">
							      <input disabled v-model="institution.institution_name" type="text" class="form-control-plaintext"
							      	placeholder="制度名称">
							    </div>
							</div>
							
							<!-- <div class="form-group row">
							    <label for="inputPassword" class="col-sm-2 col-form-label offset-sm-2">有效期开始日期</label>
							    <div class="col-sm-6">
							      <input disabled v-model="institution.effective_start_date" type="text" class="form-control-plaintext"
							      	placeholder="有效期">
							    </div>
							</div>
							
							<div class="form-group row">
							    <label for="inputPassword" class="col-sm-2 col-form-label offset-sm-2">有效期结束日期</label>
							    <div class="col-sm-6">
							      <input disabled :value="institution.effective_end_date=='2222-01-01' || institution.effective_end_date=='2099-12-31'?'长期':institution.effective_end_date" type="text" class="form-control-plaintext"
							      	placeholder="有效期">
							    </div>
							</div> -->

							<!-- <div class="form-group row">
							    <label for="inputPassword" class="col-sm-2 col-form-label offset-sm-2">使用条件</label>
							    <div class="col-sm-6 mt-2">
									
									
							      
									  <template v-for="(item, index) in institution.standard_condition_info_list">
										<div v-bind:key="index" class="mb-2">
											<span class="s-12 font-weight-bold" 
											v-if="item.rule_factor!='BOOK_SWITCH' && item.rule_factor!='SHIPPING_ADDRESS' ">
											
											<template v-if="ruleKeyMap.hasOwnProperty(item.rule_factor)">
												{{ruleKeyMap[item.rule_factor]}}
											</template>
											<template v-else>{{item.rule_factor}}</template>
											:</span>
											<span class="s-12 text-black">
												<template v-if="item.rule_factor=='AREA'">
													{{item.rule_value.mode=='notLimit'?'不限':''}}
												</template>
												<template v-if="item.rule_factor=='MEAL_MERCHANT'">
													{{item.rule_value}}
												</template>
												<template v-if="item.rule_factor=='QUOTA_TOTAL'">
													{{item.rule_value}}元
												</template>
												
												<template v-if="item.rule_factor=='MCC'">
													不限，全部餐饮商户
												</template>
												
												<template v-if="item.rule_factor=='SUPPLIER_CHANNEL'">
													饿了么
												</template>
												
												<template v-if="item.rule_factor=='ELM_MERCHANT'">
													{{item.rule_value}}
												</template>
												
												<template v-if="item.rule_factor=='TAKE_AWAY_CATEGORY'">
													{{item.rule_value}}
												</template>
												
												<template v-if="item.rule_factor=='MERCHANT'">
													{{item.rule_value}}
												</template>
												
											</span>
										</div>
										
									  </template>
								  
								  
							    </div>
							</div> -->
							
							<div class="form-group row" v-if="institution.remark">
							    <label for="inputPassword" class="col-sm-2 col-form-label offset-sm-2">备注</label>
							    <div class="col-sm-6">
							      <input disabled v-model="institution.remark" type="text" class="form-control-plaintext"
							      	placeholder="">
							    </div>
							</div>
							
						</div>
					</div>

				</div>


				<!-- //// -->
				<div class="card my-3 shadow no-b r-0">
					<div class="card-header white">
						<h6>
							发放
						</h6>
						<small>可以全员发放、按部门发放、选择指定员工发放</small>
					</div>
					<div class="card-body">
						<div class="needs-validation" novalidate="" style="height: 210px;">
							
							<div class="form-group row mb-3">
							    <label for="" class="col-sm-2 col-form-label offset-sm-2 font-weight-bold">发放金额(元)</label>
							    
								<div class="col-sm-3">
									<input v-model.number="issue_quota" type="number" class="form-control"
									placeholder="请输入发放金额" 
									>
									<div class="text-primay s-12">
										发放金额不高于{{institution.quota_total}}元
									</div>
								</div>
							</div>
							
							
							<div class="form-group row">
							    <label for="" class="col-sm-2 col-form-label offset-sm-2 font-weight-bold">发放对象</label>
							    
								<div class="col-sm-1">
									<input @change="onChangeAdapterType" v-model="adapter_type" value="0" checked type="radio" name="ms" id="ms1" class="with-gap">
									<label for="">全员</label>
								</div>
								
								<div class="col-sm-1">
									<input  @change="onChangeAdapterType" v-model="adapter_type" value="1" checked type="radio" name="ms" id="ms1" class="with-gap">
									<label for="">按部门</label>
								</div>
								
								<div class="col-sm-1">
									<input  @change="onChangeAdapterType" v-model="adapter_type" value="2" checked type="radio" name="ms" id="ms1" class="with-gap">
									<label for="">按员工</label>
								</div>
								
							</div>
							
							<div class="form-group row mb-3">
							    <label for="inputPassword" class="col-sm-2 col-form-label offset-sm-2"></label>
							    <div class="col-sm-1 form-inline">
									<button
									v-if="adapter_type==1"
									@click.stop="chooseSubDeptReady" 
									type="button" class="btn btn-outline-primary btn-sm"> 
									{{sub_dept.department_name != undefined?'选中'+sub_dept.department_name:'选择部门'}}
									</button>
									<button
									v-if="adapter_type==2"
									@click.stop="chooseStaffReady" 
									type="button" class="btn btn-outline-primary btn-sm"> 
									选择员工
									<template v-if="staff_ids.length>0">(选中{{staff_ids.length}}个)</template>
									</button>
									
								</div>
							</div>
							
							<div class="form-group row mb-3">
							    <label for="" class="col-sm-2 col-form-label offset-sm-2 font-weight-bold">审批验证码</label>
							    
								<div class="col-sm-3">
									<div class="input-group">
									  <input v-model="valid_code" type="number" class="form-control" placeholder="输入验证码" aria-describedby="button-addon2">
									  <div class="input-group-append">
									    <button @click="sendMessage" 
										:disabled="disabled" 
										v-show="show"
										class="btn btn-primary" type="button" id="button-addon2">发送</button>
										
										<button v-show="!show" class="btn btn-secondary">{{second}}秒后重发</button>
										
									  </div>
									</div>
									<div class="s-12">
										发送成功后，请向审批人员索取
									</div>
								</div>
							</div>
							
						</div>
					</div>
				</div>
				<!-- //// -->


			</div>
		</div>

		<div class="form-group mb-3">
			<!-- <button v-if="institution.id>0" @click="deleteInstitution" type="button" class="btn btn-dark"> 删除 </button> -->
			<button @click="saveReady" :disabled="disabled" type="button" class="btn btn-primary ml-2"> 确定发放 </button>
		</div>

		<!-- //main content -->


		<!-- modal -->
		<!-- 选择部门 -->
		<div class="modal fade" id="deptModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">选择部门</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">

							<div class="form-row">

								<div class="col-md-12 mb-3">
									<select class="form-control" v-model="selected_sub_dept_index">
										<option value="-1">选择部门</option>
										<option v-bind:key="index" 
											:value="index" v-for="(item,index) in subdepartmentlist">
											{{item.department_name}}</option>
									</select>
								</div>

							</div>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<button @click.stop="chooseSubDept" type="button" class="btn btn-primary"> 确定 </button>
					</div>
				</div>
			</div>
		</div>


		<!-- 选择员工 -->
		<div class="modal fade" id="staffModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="exampleModalLabel">选择员工</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">

						<div class="m-3">

							<div class="row">
								<div class="col">
									<select class="form-control" v-model="sub_dept_id">
										<option value="">所有部门</option>
										<option v-bind:key="index" 
											:value="item.id" v-for="(item,index) in subdepartmentlist">
											{{item.department_name}}</option>
									</select>
								</div>
								<!-- <div class="col">
									<input v-model="employee_name" type="text" placeholder="姓名" class="form-control" />
								</div>
								<div class="col-sm-3">
									<input v-model="employee_mobile" type="text" placeholder="手机号" class="form-control" />
								</div> -->
								
								<div class="col">
									<input v-model="query" type="text" placeholder="搜索员工姓名/手机号" class="form-control" />
								</div>
								
								<div class="col">
									<div class="float-right">
										<button @click="initSaffList" type="button" class="btn btn-dark"> 查询
										</button>
									</div>
								</div>
							</div>
							<template v-if='stafflist.length > 0'>
								<hr>
							
								<div class="card">
									<div class="card-body no-p">
										
										<div class="table-responsive">
											<div>
												<table class="table table-striped table-hover r-0 mb-0">
													<thead>
														<tr class="no-b">
															<th style="width: 60px">
																<div class="custom-control custom-checkbox">
																	<input  v-model="check_all"  @click.stop="checkAll" 
																	type="checkbox" id="checkedAll" class="custom-control-input">
																	<label class="custom-control-label" for="checkedAll"></label>
																</div>
																 <!-- <div class="d-none d-lg-block">序号</div> -->
															</th>
															<th>
																<div class="d-none d-lg-block">部门</div>
															</th>
															
															<!-- <th>
																<div class="d-none d-lg-block">工号</div>
															</th> -->
															
															<th>
																<div class="d-none d-lg-block">姓名</div>
															</th>
															
															<th>
																<div class="d-none d-lg-block">手机号码</div>
															</th>
															
															<th>
																<div class="d-none d-lg-block">账户状态</div>
															</th>
															
															<th>
																<div class="d-none d-lg-block">激活状态</div>
															</th>
														</tr>
													</thead>
											
													<tbody>
														<tr v-for="(item,index) in stafflist" v-bind:key="index" v-show="item.is_default != 1" 
														:class="{'blue lighten-5':selectedId == item.id}">
															<td>
																<div class="custom-control custom-checkbox">
																	<!-- item.status != 'ACTIVATED' || item.enabled < 1 -->
																	<input 
																	v-model="checkedIds" :value="item.id" :id="index" 
																	:disabled="item.enabled < 1"
																	type="checkbox" class="custom-control-input checkSingle"  required="">
																	<label class="custom-control-label" :for="index"></label>
																</div>
																<!-- <div class="d-none d-lg-block">{{index+1}}</div> -->
															</td>
															
															<td>
																<div class="d-none d-lg-block">
																<template v-if="subdeptmap.hasOwnProperty(item.sub_dept_id)">
																	{{subdeptmap[item.sub_dept_id].department_name}}
																</template>
																</div>
															</td>
															
															<!-- <td>
																<div class="d-none d-lg-block">{{item.employee_no}}</div>
															</td> -->
															
															<td>
																<div class="d-none d-lg-block">{{item.employee_name}}</div>
															</td>
															
															<td>
																<div class="d-none d-lg-block">{{item.employee_mobile}}</div>
															</td>
															
															<td>
																<div class="d-none d-lg-block">
																<template v-if="item.enabled == 1">
																	<span class="text-success">正常</span>
																</template>
																<template v-if="item.enabled == -1">
																	<span class="text-secondary">注销</span>
																</template>
																<template v-if="item.enabled == 0">
																	<span class="text-danger">冻结</span>
																</template>
																</div>
															</td>
															
															<td>
																<div class="d-none d-lg-block">
																<template v-if="item.status == 'ACTIVATED'">
																	<span class="text-success">已激活</span>
																</template>
																<template v-if="item.status == 'UNACTIVATED'">
																	<span class="text-secondary">未激活</span>
																</template>
																<template v-if="item.status == 'ACTIVATING'">
																	<span class="text-warning">激活中</span>
																</template>
																</div>
															</td>
															
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										
										
									</div>
								</div>
								<hr>
								<div class="row">
									<div class="col-sm-12 col-md-5">
										<div class="dataTables_info" id="example2_info" role="status" aria-live="polite">
											显示  {{show_from}} ~ {{show_to}} 条 / 共 {{count}} 条
										</div>
									</div>
									<div class="col-sm-12 col-md-7">
										
										<v-pagination :total="total" :current-page='current' @pagechange="pagechange"></v-pagination>
										
									</div>
								</div>
							</template>

						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<!-- <button @click.stop="chooseStaff" type="button" class="btn btn-primary"> 加入 </button> -->
						<button @click.stop="chooseStaff" type="button" class="btn btn-primary"> 确定 </button>
					</div>
				</div>
			</div>
		</div>


		<!-- //modal -->
	</div>
</template>

<script>
	import VPagination from '../components/my-page.vue'
	// import DatePicker from 'vue2-datepicker';
	// import 'vue2-datepicker/index.css';
	// import 'vue2-datepicker/locale/zh-cn';

	export default {
		data() {
			return {
				title: '创建制度',

				token: '',

				institution: {
					dept_id: '',
					expense_type: 'DEFAULT',
					expense_type_sub_category: '',
					share_mode: 0,
				},

				disabled: false,

				////
				departmentlist: [],
				dept_id: '',
				deptmap: {}, //key为 dept id, value为 dept
				
				scenetypelist: [
					{name: '通用(默认)', value: 'DEFAULT'}
				],
				expensetypelist: [
					{name: '选择费用类型', value: ''},
					{name: '通用(默认)', value: 'DEFAULT'},
					{name: '餐饮', value: 'MEAL'},
					// {name: '商城', value: 'MALL'},
					// {name: '公交地铁', value: 'METRO'},
					// {name: '用车', value: 'CAR'},
					// {name: '话费充值', value: 'PREPAYRECHARGE'},
					// {name: '酒店', value: 'HOTEL'},
					// {name: '火车票', value: 'TRAIN'},
					// {name: '机票', value: 'PLANE'},
				],
				subexpensetypelist: [
					{name: '选择制度类型', value: ''},
					{name: '通用', value: 'DEFAULT'},
					{name: '餐饮-到店', value: 'REACH_SHOP'},
					{name: '餐饮-外卖', value: 'TAKE_AWAY'},
					// {name: '线上商城', value: 'ONLINE_MALL'},
					// {name: '公交地铁', value: 'METRO'},
					// {name: '用车', value: 'CAR'},
					// {name: '话费充值', value: 'ONLINE_PREPAY_RECHARGE'},
					// {name: '酒店-到店住宿', value: 'REACH_SHOP_HOTEL'},
					// {name: '酒店-在线订酒店', value: 'ONLINE_HOTEL'},
					// {name: '火车票-火车票预订', value: 'ONLINE_TRAIN'},
					// {name: '机票-机票预订', value: 'ONLINE_PLANE'},
				],
				
				adapter_type: 0,//适用员工，0会员，1指定员工，2指定部门
				
				// current_date: '',
				end_date: 0,
				
				query: '',
				check_all: false,
				checkedIds: [],
				staff_ids: [],
				
				////
				ins_id: 0,//当前制度ID
				sub_dept_id: '',
				sub_dept: {},
				selected_sub_dept_index: -1,
				
				stafflist: [],
				// query: '',
				
				staff: {},
				selectedIndex: -1,
				
				selectedId: 0,
				
				// disabled: false,
				
				//
				current_page: 1,
				page_size: 50,
				
				subdepartmentlist: [],
				// sub_dept_id: '',//企业下部门，搜索条件
				subdeptmap: {}, //key为 dept id, value为 dept
				
				// current_page: 1,
				// page_size: 50,
				count: 0,
				total_page: 0,
				show_from: 0,
				show_to: 0,
				
				total: 0, // 记录总条数
				display: 10, // 每页显示条数
				current: 1, // 当前的页数
				
				employee_name: '',
				employee_mobile: '',
				
				////可读化使用条件
				ruleKeyMap:{
					"AREA": "可用位置",
					"MEAL_MERCHANT": "可用商户",//到店可用商户
					"QUOTA_TOTAL": "封顶金额",
					"MCC": "商户类型",//到店商户类型
					"SUPPLIER_CHANNEL": "供应商渠道",
					"ELM_MERCHANT": "可用商户",//外卖可用商户
					"TAKE_AWAY_CATEGORY": "商户类型",//外卖商户类型
					"SHIPPING_ADDRESS": "可用订餐地址",
					"BOOK_SWITCH": "预订",
					"MARKED_WORDS": "订餐说明",
					"MERCHANT": "可用商户",//通用可用商户
				},
				
				//本地分类
				// categorylist: [
				// 	{name: '弹性福利', value: 'DEFAULT'},
				// 	{name: '餐食外卖', value: 'MEAL'},
				// 	{name: '交通出行', value: 'CAR'},
				// 	{name: '工会福利', value: 'COMMON'},//OTHER
				// ],
				categorylist: this.GLOBAL.categorylist,
				
				issue_quota: '',//发放金额
				
				////
				valid_code: '',
				show: true,
				second: '',
				timer: null,
			}
		},

		components: {
			VPagination
			// DatePicker
		},

		created: function() {
			console.log('--- created --- ')
			let self = this;

			//1.检查登录
			let token = localStorage.getItem('TOKEN');
			if (!token) {
				return;
			}
			self.token = token;

			
			//2.取得商户信息：B企业用户登录有
			let lsLoginData = JSON.parse(localStorage.getItem('LOGINDATA'));
			self.dept_id = lsLoginData.dept_id;
			
			
			//3.取得参数 
			let id = this.$route.query.id;
			
			let nonce_str = this.$route.query.nonce_str;
			
			
			if(!id){
				self.$router.push({ path: 'institutions', params: { outlet_id: self.outlet_id }})
			}
			
			self.ins_id = id;
			self.nonce_str = nonce_str;
		},
		mounted: function() {
			console.log('--- mounted --- ')
			let self = this;

			//1.检查登录
			// let token = localStorage.getItem('TOKEN');
			// if (!self.token) {
			// 	console.log('--- mounted jump  --- ')
			// 	location.href = 'index.html';
			// 	return;
			// }

			self.init(()=>{
				self.initData();
			});
		},
		methods: {
			init(callback) {
				let self = this;
			
				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'getSubDepartmentList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,//企业ID主键
							// query: self.query,
							// current_page: self.current_page,
							// page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.subdepartmentlist = data.data.departments;
							
							for (let i = 0; i < self.subdepartmentlist.length; i++) {
								let dept = self.subdepartmentlist[i];
								self.subdeptmap[dept.id] = dept;
							}
							console.log('--- dept map ->', self.subdeptmap);
							
							// self.institution.effective_start_date = data.data.current_date;
							
							if (typeof callback == 'function') {
							    callback()
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} 
						else {
							alert(data.message)
						}
					});
				//END
			
			},
			
			initData() {
				let self = this;
				
				self.loader = self.$loading.show();

				//调用接口
				this.axios.get(this.GLOBAL.baseURI + 'getInstitution', {
						params: {
							token: self.token,
							ins_id: self.ins_id,
							nonce_str: self.nonce_str || '',
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						
						self.loader.hide();
						
						if (data.code == 200) {
							self.institution = data.data.institution;
							
							self.institution.standard_condition_info_list =  JSON.parse(self.institution.standard_condition_info_list);
					
							//发放金额
							self.issue_quota = self.institution.quota_total;
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 404) {
							 self.$router.push({ name: '404'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							alert(data.message)
						}
					});
				//END

			},

			onOutletChangeListener(e) {
				let self = this;
				console.log('--- outlet ID->', e)
				self.outlet_id = e;

				self.init();
			},


			onstatechange(state) {
				console.log('--- state  -> ', state)
			},
			
			pagechange: function(currentPage) {
				console.log(currentPage);
				// ajax请求, 向后台发送 currentPage, 来获取对应的数据
				this.current_page = currentPage;
				this.initSaffList();
			},
			
			back(){
				let self = this;
				self.$router.push({
					path: 'institutions',
					params: {
						outlet_id: self.outlet_id
					}
				})
			},
			
			saveReady(){
				let self = this;
				console.log('--- 发放范围 , adapter_type ->',self.adapter_type);
				
				if(!self.issue_quota || self.issue_quota==0){
					self.$toasted.error( '请输入发放金额', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if(self.issue_quota < 0 ){
					self.$toasted.error( '发放金额必须大于0', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				console.log('--- self.issue_quota ->',self.issue_quota);
				console.log('--- self.institution.quota_total ->',self.institution.quota_total);
				console.log(self.issue_quota > self.institution.quota_total);
				
				if(self.issue_quota > self.institution.quota_total ){
					self.$toasted.error( '发放金额不大于制度金额'+self.institution.quota_total+'元', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				if(self.adapter_type==0){
					//提示
					self.$confirm({
						title: '警告',
						message: '全员发放，将此制度额度发放给所有员工，确定发放？',
						button: {
							no: '取消',
							yes: '确定',
						},
						callback:(res)=>{
							console.log(res)
							if(res){
								self.save();
							}
							
						}
					});
					
				}
				else{//校验选择部门或员工
				
					if(self.adapter_type==1){
						if(self.sub_dept.id == undefined){
							self.$toasted.error( '请选择部门', {
								position: 'top-center',
								duration: '2000',
							})
							return;
						}
					}
					
					if(self.adapter_type==2){
						if(self.staff_ids.length==0){
							self.$toasted.error( '请选择员工', {
								position: 'top-center',
								duration: '2000',
							})
							return;
						}
					}
					
					self.save();
					
				}
				
			},

			//发送额度
			save() {
				let self = this;
				
				if (!self.valid_code) {
					self.$toasted.error('请输入审批验证码', {
						position: 'top-center',
					}).goAway(2000)
					return;
				}

				self.disabled = true;
				
				self.loader = self.$loading.show();

				// 组装数据
				let postData = {};//self.institution;

				postData['token'] = self.token;
				postData['ins_id'] = self.institution.id;
				postData["adapter_type"] = self.adapter_type;
				
				postData['sub_dept_id'] = self.sub_dept.id;
				postData['staff_ids'] = JSON.stringify(self.staff_ids);
				// postData['tag'] = self.tag;//预留
				
				postData['share_mode'] = self.share_mode?1:0;
				
				//发放金额
				postData['issue_quota'] = self.issue_quota;
				
				postData['nonce_str'] = self.nonce_str || '';
				
				//审批验证码
				postData['valid_code'] = self.valid_code;
				
				let postData2 = new FormData();
				for (var key in postData) {
					console.log(key + ':' + postData[key]);
					postData2.append(key, postData[key]);
				}

				//调用接口
				this.axios.post(this.GLOBAL.baseURI + 'addUserInstitutions',
						postData2
					)
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log('--- error->', error);
						// alert('保存失败')
						self.$toasted.error('保存失败', {
							position: 'top-center',
						}).goAway(2000)

						self.disabled = false;
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						
						self.loader.hide();

						self.disabled = false;

						if (data.code == 200) {
							if (data.data.result_code == 'success') {
								// self.$toasted.success('发放成功', {
								// 	position: 'top-center',
								// }).goAway(2000)
								
								self.$confirm({
									title: '提交成功',
									message: '请稍候查询发放记录.',
									button: {
										no: '取消',
										yes: '确定',
									},
									callback:(res)=>{
										console.log(res)
										// if(res){
											setTimeout(()=>{
												self.$router.push({
													path: 'userinstitutions',
													params: {
														outlet_id: self.outlet_id
													}
												})
											},1000)
										// }
										
									}
								});

								// self.$router.push({
								// 	path: 'userinstitutions',
								// 	params: {
								// 		outlet_id: self.outlet_id
								// 	}
								// })
							}
							else{
								self.$toasted.error( data.data.result_msg ,{
									position: 'top-center',
								}).goAway(2000)
							}
						} 
						else if (data.code == 403) {
							 // Vue.toasted.error( '没有权限', {
							 // 	position: 'top-center',
							 // 	duration: '2000',
							 // })
							 
							 self.$router.push({ name: '403'});
							 
							 return;
						}
						else if (data.code == 404) {
							 self.$router.push({ name: '404'});
							 
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} else {
							// alert(data.message)

							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
				//END
			},
			
			
			onChangeAdapterType(){
				console.log('--- onChangeAdapterType ---')
				let self = this;
				
				//初始化
				self.sub_dept = {};
				
				self.sub_dept_id = '';
				self.employee_name = '';
				self.employee_mobile = '';
				self.checkedIds = [];
			},
			
			chooseSubDeptReady(){
				let self = this;
				self.sub_dept = {};
				
				$('#deptModal').modal('show');
			},
			
			chooseSubDept(){
				let self = this;
				
				if(self.selected_sub_dept_index==-1){
					self.$toasted.error( '请选择部门', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				let dept = self.subdepartmentlist[self.selected_sub_dept_index];
				self.sub_dept = Object.assign({}, dept);
				
				$('#deptModal').modal('hide');
			},
			
			chooseStaffReady(){
				let self = this;
				
				self.initSaffList(()=>{
					self.sub_dept_id = '';
					self.employee_name = '';
					self.employee_mobile = '';
					self.query = '';
					
					self.checkedIds = [];
					
					$('#staffModal').modal('show');
				})
			},
			
			checkAll() {
				let self = this;
				self.check_all = !self.check_all;
				console.log('--- check all ---', self.check_all)
			
				if (self.check_all) {
					for (var i = 0; i < self.stafflist.length; i++) {
						if (self.stafflist[i].status != 'ACTIVATED') continue;
						if (self.stafflist[i].enabled < 1) continue;
						
						self.checkedIds.push(self.stafflist[i].id);
					}
				} else {
					self.checkedIds = [];
				}
			},
			
			//暂存选择的指定员工
			chooseStaff() {
				let self = this;
				console.log('--- checkedIds ---', self.checkedIds)
				
				if(self.checkedIds.length==0){
					self.$toasted.error( '请选择员工', {
						position: 'top-center',
						duration: '2000',
					})
					return;
				}
				
				self.staff_ids = self.checkedIds;
				console.log('--- staff_ids @ chooseStaff>', self.staff_ids);
				
				$('#staffModal').modal('hide');
			},
			
			
			////
			initSaffList(callback) {
				let self = this;
			
				//
				this.axios.get(this.GLOBAL.baseURI + 'getStaffList', {
						params: {
							token: self.token,
							dept_id: self.dept_id,
							sub_dept_id: self.sub_dept_id,
							query: self.query,
							employee_name: self.employee_name,
							employee_mobile: self.employee_mobile,
							current_page: self.current_page,
							page_size: self.page_size,
						}
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
						if (data.code == 200) {
							self.stafflist = data.data.staff_list;
							// __LIST = data.data.staff_list;
							
							// let staffs = [];
							// for(let i=0; i<data.data.staff_list.length; i++){
							// 	let staff = data.data.staff_list[i];
							// 	console.log('--- self.subDeptObj ->',staff.dept_id, self.subDeptObj[staff.dept_id+""])
							// 	staff.sub_department_name = self.subDeptObj[staff.dept_id];
							// 	staffs.push(staff);
							// }
							// self.stafflist = staffs;
				// 			__LIST = staffs;
				
							self.current_page = data.data.page_num;
							self.page_size = data.data.page_size;
							self.count = data.data.total;
							self.total_page = Math.ceil(self.count / self.page_size);
							console.log('--- self.total_page ->', self.total_page)
							
							self.show_from = self.page_size * (self.current_page - 1) + 1;
							self.show_to = self.page_size * self.current_page <= self.count ? self.page_size * self
								.current_page : self.count;
							
							self.total = self.count; // 记录总条数
							self.display = self.page_size; // 每页显示条数
							self.current = self.current_page; // 当前的页数
							
							if (typeof callback == 'function') {
							    callback()
							}
						} 
						else if (data.code == 101) {
							//登录超时
							// location.href = "index.html";
							self.logoff();
						} 
						else {
							alert(data.message)
						}
					});
				//END
			
			},
			
			////
			sendMessage(){
				let self = this;
				
				// self.$toasted.success('发送成功', {
				// 	position: 'top-center',
				// }).goAway(2000)
				
				// //倒计时
				// self.countdown();
				
				if(self.disabled) return;
			
				self.disabled = true;
			
				// 组装数据
				let postData = {
					token: self.token,
				}
				
				console.log('--- postData ->',postData)
			
				this.axios.get(this.GLOBAL.baseURI + 'sendQuotaIssueMessage', {
						params: postData
					})
					.then(function(response) {
						console.log(response);
						return response.data;
					})
					.catch(function(error) {
						console.log(error);
					})
					.then(function(data) {
						// always executed
						console.log(data.data);
			
						self.disabled = false;
			
						if (data.code == 200) {
							
							self.$toasted.success('发送成功', {
								position: 'top-center',
							}).goAway(2000)
							
							//倒计时
							self.countdown();
						} 
						else if (data.code == 403) {
							 self.$router.push({ name: '403'});
							 return;
						}
						else if (data.code == 101) {
							//登录超时
							self.logoff();
						} 
						else {
							self.$toasted.error(data.message, {
								position: 'top-center',
							}).goAway(2000)
						}
					});
			},
			
			countdown() {
				const TIME_COUNT = 60;
				if (!this.timer) {
					this.second = TIME_COUNT;
					this.show = false;
					this.timer = setInterval(() => {
						if (this.second > 0 && this.second <= TIME_COUNT) {
							this.second--;
						} else {
							this.show = true;
							clearInterval(this.timer);
							this.timer = null;
						}
					}, 1000)
				}
			},

		},
	}
</script>

<style scoped>
	
	.form-group {
	    margin-bottom: 0;
	}

</style>
